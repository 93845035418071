/*
Selects the label following the radio checkbox that
was checked
*/
[name="shape"]:checked + label {
    border: 2px solid #664DE5;
  }

  [name="shape"] {
    position: fixed;
    opacity: 0;
  }
