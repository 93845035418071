@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");

@layer base {
	html {
		font-family: "Inter", sans-serif;
	}
}
